<template>
  <v-container class="plans sw-pb-5">
    <v-window v-model="window">
      <v-window-item :value="1">
        <v-layout row wrap class="align-end sw-mb-4">
          <v-spacer></v-spacer>
          <v-flex xs4 class="text-center sw-h3 font-weight-light"
            >Choose your package</v-flex
          >
          <v-flex xs4 class="text-right font-weight-bold"
            ><span
              class="sw-body-2 font-weight-medium underline cursor-pointer"
              @click="window = 2"
              >Treffio Enterprise: make Treffio your own</span
            ></v-flex
          >
        </v-layout>
        <v-layout row class="sw-mb-4 sw-mx-n2">
          <v-flex
            v-for="(plan, i) in plans"
            :key="`plan-${i}`"
            xs6
            class="sw-px-2"
          >
            <v-card
              class="fill-height sw-pa-3 cursor-default"
              :class="{ selected: selectedPlanId === plan.id }"
              @click="selectPlan(plan.id)"
            >
              <div class="sw-mb-2 sw-h4 font-weight-light black--text">
                {{ plan.name }}
                <span class="sw-pl-1 sw-body-2">{{ plan.description }}</span>
              </div>
              <v-layout row>
                <v-flex xs8 class="sw-pr-3">
                  <div
                    v-for="(feature, p) in plan.features"
                    :key="`feature-${p}`"
                    class="sw-mb-1 sw-caption"
                  >
                    <font-awesome-icon
                      icon="check-circle"
                      color="green"
                      class="sw-mr-1 sw-body-1"
                    />
                    <span class="sw-body-1 font-weight-light black--text">{{
                      feature
                    }}</span>
                  </div>
                </v-flex>
                <v-flex xs4 class="align-self-end black--text">
                  <div class="sw-mb-4">
                    <div class="font-weight-medium">Details</div>
                    <div class="sw-caption">
                      * Number of guests per event:
                      {{ plan.max_users }}
                    </div>
                    <div class="sw-caption">
                      * Number of active events: {{ plan.max_groups }}
                    </div>
                    <div class="sw-caption">
                      * Number of backend users per event:
                      {{ plan.max_admin_users }}
                    </div>
                  </div>
                  <div class="sw-h5 black--text">
                    {{ plan.price / 100 }}
                    {{ plan.price_currency }}/month
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <div v-if="!currentUserPlanId" class="text-center">
          <v-btn
            round
            large
            class="sw-accent-bg sw-on-accent text-none"
            :disabled="!selectedPlanId"
            :loading="isLoading"
            @click="paymentDetails = true"
            >Choose package</v-btn
          >
        </div>
        <v-layout v-if="currentUserPlanId" row class="justify-center">
          <v-flex xs8>
            <v-layout
              row
              wrap
              class="align-center sw-body-1 font-weight-medium"
            >
              <v-flex xs4 class="text-right sw-accent"
                >Call us:
                <a href="tel:+4570234466" class="sw-accent"
                  >+45 70 23 44 66</a
                ></v-flex
              >
              <v-flex xs4 class="text-center sw-accent"
                >Send us a mail:
                <a href="mailto:support@treffio.com" class="sw-accent"
                  >support@treffio.com</a
                ></v-flex
              >
              <!-- <v-flex xs4 class="text-left sw-accent">Start a chat</v-flex> -->
            </v-layout>
          </v-flex>
        </v-layout>
      </v-window-item>
      <v-window-item :value="2">
        <v-layout row wrap class="align-end sw-mb-1">
          <v-flex xs4
            ><span
              class="sw-body-2 font-weight-medium underline cursor-pointer"
              @click="window = 1"
              >Back to "Choose your package"</span
            ></v-flex
          >
          <v-flex xs4 class="sw-h3 font-weight-light text-center"
            >Treffio Enterprise</v-flex
          >
          <v-spacer></v-spacer>
        </v-layout>
        <div class="sw-mb-4 text-center">
          <span class="sw-body-2 font-weight-medium underline"
            >Make Treffio your own</span
          >
        </div>
        <v-layout row wrap class="sw-py-3 justify-center">
          <v-flex xs8 class="sw-pr-3">
            <v-layout row wrap class="sw-mb-4">
              <v-flex xs6 class="sw-body-1 font-weight-light">
                <p>
                  Treffio is a modern event system ­powered by the latest
                  technology, that empowers event makers to make better events.
                </p>
                <p><br /></p>
                <p>
                  Offer native app capabilities instead of ­outdated web apps,
                  so guests can ­access what is important with one press on
                  their phone accessing the app.
                </p>
                <p><br /></p>
                <p>
                  Get automatically assembled landing ­pages for the
                  web-experience, in one and the same system. Make tickets, that
                  can be scanned through the Treffio administration app and much
                  much more.
                </p>
                <p><br /><br /></p>
                <p>Welcome to the next level of ­event-making.</p>
              </v-flex>
              <v-flex xs6 class="sw-pl-4">
                <div
                  v-for="(feature, i) in enterprisePlan.features"
                  :key="`feature-${i}`"
                  class="sw-mb-1 sw-caption"
                >
                  <font-awesome-icon
                    icon="check-circle"
                    color="green"
                    class="sw-mr-1 sw-body-1"
                  />
                  <span class="sw-body-1 font-weight-light black--text">{{
                    feature
                  }}</span>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout row class="justify-center">
          <v-flex xs8>
            <v-layout
              row
              wrap
              class="align-center sw-body-1 font-weight-medium"
            >
              <v-flex xs4 class="text-right sw-accent"
                >Call us:
                <a href="tel:+4570234466" class="sw-accent"
                  >+45 70 23 44 66</a
                ></v-flex
              >
              <v-flex xs4 class="text-center sw-accent"
                >Send us a mail:
                <a href="mailto:support@treffio.com" class="sw-accent"
                  >support@treffio.com</a
                ></v-flex
              >
              <!-- <v-flex xs4 class="text-left sw-accent">Start a chat</v-flex> -->
            </v-layout>
          </v-flex>
        </v-layout>
      </v-window-item>
      <v-window-item :value="3">
        <v-layout row wrap class="align-end sw-mb-4">
          <v-flex xs12 class="sw-h3 font-weight-light text-center"
            >Card added successfully!</v-flex
          >
        </v-layout>
        <v-layout
          v-if="selectedPlan && selectedPlan.id"
          row
          class="sw-mb-4 justify-center"
        >
          <v-flex xs12 class="sw-pa-3">
            <div class="sw-mb-2 sw-h4 font-weight-light black--text">
              {{ selectedPlan.name }}
              <span class="sw-pl-1 sw-body-2">{{
                selectedPlan.description
              }}</span>
            </div>
            <v-layout row>
              <v-flex xs8 class="sw-pr-3">
                <div
                  v-for="(feature, i) in selectedPlan.features"
                  :key="`feature-${i}`"
                  class="sw-mb-1 sw-caption"
                >
                  <font-awesome-icon
                    icon="check-circle"
                    color="green"
                    class="sw-mr-1 sw-body-1"
                  />
                  <span class="sw-body-1 font-weight-light black--text">{{
                    feature
                  }}</span>
                </div>
              </v-flex>
              <v-flex xs4 class="align-self-end">
                <div class="sw-mb-4 black--text">
                  <div class="font-weight-medium">Details</div>
                  <div class="sw-caption">
                    * Number of guests per event:
                    {{ selectedPlan.max_users }}
                  </div>
                  <div class="sw-caption">
                    * Number of active events: {{ selectedPlan.max_groups }}
                  </div>
                  <div class="sw-caption">
                    * Number of backend users per event:
                    {{ selectedPlan.max_admin_users }}
                  </div>
                </div>
                <div class="sw-h5 black--text">
                  {{ selectedPlan.price / 100 }}
                  {{ selectedPlan.price_currency }}/month
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <div v-if="selectedPlan && selectedPlan.id" class="text-center">
          <v-btn
            round
            large
            class="sw-accent-bg sw-on-accent text-none"
            :loading="isLoading"
            @click="subscribePlan"
            >Subscribe {{ selectedPlan.price / 100 }}
            {{ selectedPlan.price_currency }}/month</v-btn
          >
        </div>
      </v-window-item>
      <v-window-item :value="4">
        <v-layout row wrap class="align-end sw-mb-4">
          <v-flex xs12 class="sw-h3 font-weight-light text-center"
            >Subscription confirmed successfully!</v-flex
          >
        </v-layout>
        <v-layout
          v-if="selectedPlan && selectedPlan.id"
          row
          class="sw-mb-4 justify-center"
        >
          <v-flex xs12 class="sw-pa-3">
            <div class="sw-mb-2 sw-h4 font-weight-light black--text">
              {{ selectedPlan.name }}
              <span class="sw-pl-1 sw-body-2">{{
                selectedPlan.description
              }}</span>
            </div>
            <v-layout row>
              <v-flex xs8 class="sw-pr-3">
                <div
                  v-for="(feature, i) in selectedPlan.features"
                  :key="`feature-${i}`"
                  class="sw-mb-1 sw-caption"
                >
                  <font-awesome-icon
                    icon="check-circle"
                    color="green"
                    class="sw-mr-1 sw-body-1"
                  />
                  <span class="sw-body-1 font-weight-light black--text">{{
                    feature
                  }}</span>
                </div>
              </v-flex>
              <v-flex xs4 class="align-self-end">
                <div class="sw-mb-4 black--text">
                  <div class="font-weight-medium">Details</div>
                  <div class="sw-caption">
                    * Number of guests per event:
                    {{ selectedPlan.max_users }}
                  </div>
                  <div class="sw-caption">
                    * Number of active events: {{ selectedPlan.max_groups }}
                  </div>
                  <div class="sw-caption">
                    * Number of backend users per event:
                    {{ selectedPlan.max_admin_users }}
                  </div>
                </div>
                <div class="sw-h5 black--text">
                  {{ selectedPlan.price / 100 }}
                  {{ selectedPlan.price_currency }}/month
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <div class="text-center">
          <v-btn
            round
            large
            class="sw-accent-bg sw-on-accent text-none"
            :loading="isLoading"
            @click="$router.push({ name: 'groups' })"
            >Continue</v-btn
          >
        </div>
      </v-window-item>
    </v-window>
    <PaymentDetails v-model="paymentDetails" @success="window = 3" />
  </v-container>
</template>

<script>
import plans from "@/components/Plans/plans.json";
import PaymentDetails from "@/components/Stripe/PaymentDetails.vue";

export default {
  data: () => ({
    isLoading: false,
    window: 1,
    plans: [],
    selectedPlanId: null,
    paymentDetails: false,
  }),
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    currentUserPlanId() {
      return this.currentUser.plan_id;
    },
    enterprisePlan() {
      return plans.find((el) => el.slug === "enterprise");
    },
    selectedPlan() {
      return this.plans.find((el) => el.id === this.selectedPlanId);
    },
  },
  components: {
    PaymentDetails,
  },
  watch: {
    currentUserPlanId: {
      immediate: true,
      handler(val) {
        if (val) this.selectedPlanId = val;
      },
    },
    window() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    this.listPlans();
  },
  methods: {
    async listPlans() {
      try {
        this.isLoading = true;

        const response = await this.$api.plans.list();

        this.isLoading = false;

        if (!response.data.data) return;

        const availablePlans = response.data.data.filter((el) => !el.read_only);

        for (const plan of availablePlans) {
          this.plans.push({
            ...plan,
            ...plans.find((el) => el.slug === plan.slug),
          });
        }
      } catch (error) {
        if (error) {
          this.errorMessageShow(error);
          this.isLoading = false;
        }
      }
    },
    selectPlan(planId) {
      if (!this.currentUserPlanId) this.selectedPlanId = planId;
    },
    async subscribePlan() {
      try {
        this.isLoading = true;

        await this.$api.plans.subscribe(this.selectedPlanId);

        const profileResponse = await this.$api.auth.me();

        this.$store.commit("SET_USER", profileResponse.data.data);

        this.isLoading = false;

        this.window = 4;
      } catch (error) {
        if (error) {
          this.errorMessageShow(error);
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.underline {
  padding-bottom: 3px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    background: red;
  }
}

.v-card {
  border-radius: 19px !important;
  transition: all 0.2s;
  -webkit-box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.05);

  &.selected {
    background-color: #f2f2f2;
  }

  &:hover:not(.selected) {
    -webkit-box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.09);
  }
}
</style>
